<template>
  <div class="uppost" ref="upPage">
    <Nav></Nav>
    <div class="uppost-con">
      <div class="uppost-title">
        <span @click="onGoIndex">首页</span>>>上传视频
      </div>
      <div class="uppost-detail">
        <div class="small-pic-preview">
          <img
            v-for="pic in picList"
            :src="pic.url"
            :key="'pic_small_' + pic.key"
          />
        </div>
        <!-- <div class="add-uuid">
          <el-button @click="onAddPic()">添加图片</el-button>
          <el-button @click="onUsePicFileAsPicText()"
            >以文件名作为图片说明</el-button
          >
        </div> -->
        <div class="up-con">
          <div class="up-left">
            <div>
              <span class="title">新闻标题：</span>
              <el-input
                v-model="post.title"
                placeholder
                ref="postTitle"
              ></el-input>
            </div>
            <div>
              <span class="title">目标稿库：</span>
              <el-radio-group v-model="post.targetStore" class="content">
                <el-radio
                  v-for="upStore in canUploadStore"
                  :key="'store_' + upStore.id"
                  class="radio"
                  :label="upStore.id"
                  >{{ upStore.name }}</el-radio
                >
              </el-radio-group>
            </div>
            <div>
              <span class="title">发生时间：</span>
              <el-date-picker
                v-model="post.eventTime"
                type="date"
                value-format="yyyy-MM-dd"
                :default-value="new Date()"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
            <div>
              <span class="title">发生地点：</span>
              <el-cascader
                class="content"
                :options="$rtm.store.addrTree"
                :props="types_picker_props"
                filterable
                clearable
                separator=" > "
                v-model="addr_picker_arr"
              ></el-cascader>
            </div>
            <div>
              <span class="title">稿件类型1：</span>
              <el-cascader
                class="content"
                :options="$rtm.store.typeTree"
                :props="types_picker_props"
                filterable
                clearable
                separator=">"
                v-model="type_picker_arr1"
              ></el-cascader>
            </div>
            <div>
              <span class="title">稿件类型2：</span>
              <el-cascader
                class="content"
                :options="$rtm.store.typeTree"
                :props="types_picker_props"
                filterable
                clearable
                separator=">"
                v-model="type_picker_arr2"
              ></el-cascader>
            </div>
            <div>
              <span class="title">稿件类型3：</span>
              <el-cascader
                class="content"
                :options="$rtm.store.typeTree"
                :props="types_picker_props"
                filterable
                clearable
                separator=">"
                v-model="type_picker_arr3"
              ></el-cascader>
            </div>
            <div>
              <span class="title">署名：</span>
              <el-input v-model="post.author" placeholder></el-input>
            </div>
            <div>
              <span class="title">作者前缀：</span>
              <el-autocomplete
                class="input-autocomplete"
                v-model="post.authorExtra1"
                :fetch-suggestions="suggestAuthor"
                placeholder="请选择前缀"
              ></el-autocomplete>
            </div>
          </div>
          <div class="up-right">
            <div>
              稿件正文：
              <el-input
                v-model="post.mainText"
                type="textarea"
                :rows="7"
                ref="postMainText"
              ></el-input>
            </div>
            <div>
              备注：
              <el-input
                v-model="post.note"
                type="textarea"
                :rows="4"
                ref="postNote"
              ></el-input>
            </div>
            <el-button class="save" @click="onSavePost()">保存</el-button>
          </div>
        </div>
        <div class="add-uuid">
          <div class="add-video">
            <div class="top">
              <el-upload
                v-loading="uploading"
                class="avatar-uploader"
                :action="videoUrl"
                :show-file-list="false"
                :on-change="onVideoSelected"
                :on-progress="onUploadProgress"
                :on-success="onGetVideoURL"
                :with-credentials="true"
                accept=".mp4, .avi, .MP4, .AVI"
              >
                <el-button> 添加视频 </el-button>
              </el-upload>

              <div class="video-progress">
                <div
                  class="progress-bar"
                  :class="{ finish: videoProgress == 100.0 }"
                  :style="{ width: videoProgress + '%' }"
                ></div>
                <div class="progress-title" v-if="videoProgress != 100.0">
                  视频上传进度：{{ videoProgress }}%
                </div>
                <div class="progress-title" v-if="videoProgress == 100.0">
                  视频上传完毕
                </div>
              </div>
            </div>
            <div class="show">
              <video :src="videoImg" controls width="98%" height="400"></video>
            </div>
          </div>
          <div class="add-poster">
            <div class="top">
              <div class="button-line">
                <el-upload
                  class="avatar-uploader"
                  :action="postImage"
                  :show-file-list="false"
                  :on-progress="onUploadPicProgress"
                  :on-success="onGetPosterURL"
                  :with-credentials="true"
                  accept=".jpg, .jpeg, .JPG, .JPEG"
                >
                  <el-button>添加封面</el-button>
                </el-upload>
                <el-button :disabled="!imgURL" @click="onPosterCutClick"
                  >剪裁封面</el-button
                >
                <div v-if="imgURL" class="tip">已经剪裁至16：9尺寸</div>
              </div>
              <div class="pic-progress">
                <div
                  class="progress-bar"
                  :class="{ finish: videoPosterProgress == 100.0 }"
                  :style="{ width: videoPosterProgress + '%' }"
                ></div>
                <div class="progress-title" v-if="videoPosterProgress != 100.0">
                  图片上传进度：{{ videoPosterProgress }}%
                </div>
                <div class="progress-title" v-if="videoPosterProgress == 100.0">
                  图片上传完毕
                </div>
              </div>
            </div>
            <div class="show">
              <div class="poster-view" v-if="imgURL">
                <div
                  class="poster-view-inner"
                  :style="{
                    backgroundImage: 'url(' + imgURL + ')',
                    left:
                      '' +
                      -(1 / (posterCutX2 - posterCutX1)) * posterCutX1 * 100 +
                      '%',
                    width: '' + (1 / (posterCutX2 - posterCutX1)) * 100 + '%',
                    top:
                      '' +
                      -(1 / (posterCutY2 - posterCutY1)) * posterCutY1 * 100 +
                      '%',
                    height: '' + (1 / (posterCutY2 - posterCutY1)) * 100 + '%',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        class="poster-cut-dialog"
        :visible.sync="posterCutDialog"
        title="封面剪裁(移动四个角和任意图片内部位置调整)"
        width="80%"
      >
        <div class="poster-cut-container">
          <div class="poster-cut-view">
            <canvas
              width="544"
              height="306"
              ref="poster-cut-desktop"
              @mousedown="onPosterCutMouseDown($event)"
              @mousemove="onPosterCutMouseMove($event)"
              @mouseup="onPosterCutMouseEnd($event)"
              @mouseleave="onPosterCutMouseEnd($event)"
            ></canvas>
          </div>
          <div class="poster-cut-view">
            <div
              class="poster-cut-view-inner"
              :style="{
                backgroundImage: 'url(' + imgURL + ')',
                left:
                  '' +
                  -(1 / (posterCutX2 - posterCutX1)) * posterCutX1 * 100 +
                  '%',
                width: '' + (1 / (posterCutX2 - posterCutX1)) * 100 + '%',
                top:
                  '' +
                  -(1 / (posterCutY2 - posterCutY1)) * posterCutY1 * 100 +
                  '%',
                height: '' + (1 / (posterCutY2 - posterCutY1)) * 100 + '%',
              }"
            />
            <div class="title">预览</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      uploading: false,
      post: {
        title: "",
        author: "",
        checked: false,
        mainText: "",
        note: "",
        type: [],
        eventTime:
          new Date().getFullYear() +
          "-" +
          ("" + (new Date().getMonth() + 1)).padStart(2, "0") +
          "-" +
          ("" + new Date().getDate()).padStart(2, "0"),
        targetStore: 0,
      },
      types_picker_props: {
        value: "id",
        label: "name",
        children: "subs",
        checkStrictly: true,
      },
      type_picker_arr1: [],
      type_picker_arr2: [],
      type_picker_arr3: [],
      addr_picker_arr: [],
      picList: [],
      atopics: [],
      authorList: [
        { value: "融媒记者" },
        { value: "美美资料图片" },
        { value: "特约摄影师" },
      ],
      postImage: "", // 视频封面
      imgURL: "", // 本地图片路径
      videoImg: "", // 视频的显示
      videoUrl: "", // 本地视频路径显示
      posterUUID: "", // 上传后的UUID
      videoUUID: "", // 上传后的视频uuid
      unUsePoster: false, // 封面不合格
      posterErrMsg: "",
      canUploadStore: [], // 可以上传的视频库
      videoProgress: 0, // 视频上传进度条
      videoPosterProgress: 0, // 视频封面上传进度条
      posterCutX1: 0,
      posterCutY1: 0,
      posterCutX2: 1,
      posterCutY2: 1,
      posterCutDialog: false,
      mouseDownX: 0,
      mouseDownY: 0,
      mouseDown: false,
      mouseAnchorPosition: 0, // 1 2 3 4 5
      posterCutDataWhenDown: [],
      drawX1: 0,
      drawY1: 0,
      drawX2: 0,
      drawY2: 0,
      drawWidth: 0,
      drawHeight: 0,
      drawX: 0,
      drawY: 0,
      drawImage: undefined,
      posterOriHeight: 0,
      posterOriWidth: 0,
    };
  },
  created() {
    const thiz = this;
    this.reload();
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        this.post.targetStore = data.upVideoStores[0].id;
        this.post.author = data.chnName;
        this.canUploadStore = data.upVideoStores;
        if (data.upVideoStores.length != 0) {
          var newarr = [];
          data.upVideoStores.forEach((k) => {
            newarr.push(k.id);
          });
          if (newarr.indexOf(65536) != -1) {
            this.post.targetStore = 65536;
          } else {
            this.post.targetStore = data.upVideoStores[0].id;
          }
        } else {
          thiz.$message("当前账户没有上传视频的库");
        }
      })
      .catch(() => {
        this.$router.push("/index.html");
      });
    thiz.postImage = RD.myPost().upPosterFile_URL();
    thiz.videoUrl = RD.myPost().upVideoFile_URL();
  },
  mounted() {
    this.$refs.postTitle.$refs.input.ondrop = function (e) {
      e.stopPropagation();
    };
    this.$refs.postMainText.$refs.textarea.ondrop = function (e) {
      e.stopPropagation();
    };
    this.$refs.postNote.$refs.textarea.ondrop = function (e) {
      e.stopPropagation();
    };
  },
  methods: {
    reload() {
      const thiz = this;
      if (this.$rtm.store.typeTree.length == 0) {
        RD.type()
          .tree()
          .then((data) => {
            this.$rtm.store.typeTree = data;
          });
      }
      if (this.$rtm.store.addrTree.length == 0) {
        RD.addr()
          .tree()
          .then((data) => {
            this.$rtm.store.addrTree = data;
          });
      }
      thiz.type_picker_arr = thiz.treeList;
    },
    onSavePost() {
      var thiz = this;
      if (this.postImage == "") {
        this.$message({
          type: "warning",
          message: "没有上传图片！",
        });
        return;
      }
      if (this.post.title.length == 0) {
        this.$message({
          type: "warning",
          message: "标题不能为空",
        });
        return;
      }
      if (this.post.targetStore == 0) {
        this.$message({
          type: "warning",
          message: "请选择输出稿库",
        });
        return;
      }
      if (this.addr_picker_arr.length == 0) {
        this.$message({
          type: "warning",
          message: "请选则发生地点",
        });
        return;
      }

      if (this.addr_picker_arr.length == 1) {
        if (this.addr_picker_arr[0] != 13) {
          this.$message({
            type: "warning",
            message: "请选择二级地点",
          });
          return;
        }
      }

      if (
        this.type_picker_arr1.length == 0 &&
        this.type_picker_arr2.length == 0 &&
        this.type_picker_arr3.length == 0
      ) {
        this.$message({
          type: "warning",
          message: "请选择至少一个类型",
        });
        return;
      }

      if (this.post.author.length == 0) {
        this.$message({
          type: "warning",
          message: "请输入署名",
        });
        return;
      }
      if (this.post.eventTime.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择发生时间",
        });
        return;
      }
      if (this.post.mainText.length == 0) {
        this.$message({
          type: "warning",
          message: "请输入正文",
        });
        return;
      }

      if (thiz.unUsePoster == false) {
        this.$message({
          type: "warning",
          message: thiz.posterErrMsg,
        });
        return;
      }

      if (thiz.posterUUID == "") {
        if (parseInt(this.videoPosterProgress) != 0) {
          this.$message({
            type: "warning",
            message: "请等待封面上传完毕",
          });
        } else {
          this.$message({
            type: "warning",
            message: "请先上传封面",
          });
        }
        return;
      }

      if (thiz.videoUUID == "") {
        if (parseInt(this.videoProgress) != 0) {
          this.$message({
            type: "warning",
            message: "请等待视频上传完毕",
          });
        } else {
          this.$message({
            type: "warning",
            message: "请先上传视频",
          });
        }

        return;
      }
      var post = {};
      post.storeId = this.post.targetStore;
      post.title = this.post.title;
      post.author = this.post.author;
      post.authorExtra1 = this.post.authorExtra1;
      post.mainText = this.post.mainText;
      post.note = this.post.note;
      post.eventTime = this.post.eventTime;
      post.eventAddrId = this.addr_picker_arr[this.addr_picker_arr.length - 1];
      post.typeIds = [];
      post.posterUUID = thiz.posterUUID;
      post.videoUUID = thiz.videoUUID;
      post.posterCuts = [
        thiz.posterCutX1,
        thiz.posterCutY1,
        thiz.posterCutX2,
        thiz.posterCutY2,
      ];
      if (this.type_picker_arr1.length != 0) {
        post.typeIds.push(
          this.type_picker_arr1[this.type_picker_arr1.length - 1]
        );
      }
      if (this.type_picker_arr2.length != 0) {
        post.typeIds.push(
          this.type_picker_arr2[this.type_picker_arr2.length - 1]
        );
      }
      if (this.type_picker_arr3.length != 0) {
        post.typeIds.push(
          this.type_picker_arr3[this.type_picker_arr3.length - 1]
        );
      }

      var arrs = [];
      post.typeIds.forEach((k) => {
        if (arrs.indexOf(k) == -1) {
          arrs.push(k);
        }
      });

      post.typeIds = arrs;

      console.log("xujiwu", post);

      RD.myPost()
        .upVideoPost(post)
        .then(() => {
          thiz.$router.push("/myPost.html");
        });
    },
    // 上传海报成功后的处理
    onGetPosterURL(res, file) {
      console.log("res", res);
      let thiz = this;
      if (res.code == 0) {
        thiz.unUsePoster = true;
        thiz.imgURL = URL.createObjectURL(file.raw);
        thiz.posterCutInit();
        thiz.posterUUID = res.data;
      } else {
        // thiz.$message(res.msg)
        this.$alert(res.msg, "提示", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
        thiz.unUsePoster = false;
        thiz.posterErrMsg = res.msg;
        thiz.videoPosterProgress = 0;
        thiz.imgURL = "";
      }
    },
    posterCutInit() {
      let thiz = this;
      var image = new Image();
      image.onload = () => {
        var cwidth = 544;
        var cheight = 306;
        this.posterOriHeight = image.height;
        this.posterOriWidth = image.width;
        if (this.posterOriWidth / this.posterOriHeight > 16 / 9) {
          var targetWidth = (this.posterOriHeight / 9) * 16;
          var tagetHeight = this.posterOriHeight;
          thiz.posterCutX1 =
            (this.posterOriWidth - targetWidth) / this.posterOriWidth / 2;
          thiz.posterCutX2 =
            1 - (this.posterOriWidth - targetWidth) / this.posterOriWidth / 2;
          thiz.posterCutY1 = 0;
          thiz.posterCutY2 = 1;
          thiz.drawWidth = cwidth;
          thiz.drawHeight =
            (thiz.drawWidth / this.posterOriWidth) * this.posterOriHeight;
          thiz.drawX = 0;
          thiz.drawY = (cheight - thiz.drawHeight) / 2;
        } else {
          var targetWidth = this.posterOriWidth;
          var tagetHeight = (this.posterOriWidth / 16) * 9;
          thiz.posterCutX1 = 0;
          thiz.posterCutX2 = 1;
          thiz.posterCutY1 =
            (this.posterOriHeight - tagetHeight) / this.posterOriHeight / 2;
          thiz.posterCutY2 =
            1 - (this.posterOriHeight - tagetHeight) / this.posterOriHeight / 2;
          thiz.drawHeight = cheight;
          thiz.drawWidth =
            (thiz.drawHeight / this.posterOriHeight) * this.posterOriWidth;
          thiz.drawY = 0;
          thiz.drawX = (cwidth - thiz.drawWidth) / 2;
        }
        this.drawImage = image;
      };
      image.src = thiz.imgURL;
    },
    onPosterCutClick() {
      let thiz = this;
      thiz.posterCutDialog = true;
      thiz.$nextTick(() => {
        thiz.posterCutCanvasDraw();
      });
    },
    suggestAuthor(queryString, cb) {
      console.log("suggestPage", this.newsPaperList);
      cb(this.authorList);
    },
    onPosterCutMouseDown(event) {
      let thiz = this;
      var canvas = thiz.$refs["poster-cut-desktop"];
      var mouseX = event.clientX - canvas.getBoundingClientRect().left;
      var mouseY = event.clientY - canvas.getBoundingClientRect().top;
      thiz.mouseDownX = mouseX;
      thiz.mouseDownY = mouseY;
      console.log(mouseX, mouseY);
      thiz.posterCutDataWhenDown = [
        thiz.posterCutX1,
        thiz.posterCutY1,
        thiz.posterCutX2,
        thiz.posterCutY2,
      ];
      var position = thiz.checkPosition(mouseX, mouseY);
      if (position > 0) {
        this.mouseDown = true;
        this.mouseAnchorPosition = position;
      }
      console.log(this.mouseAnchorPosition, this.mouseDown);
    },
    onPosterCutMouseMove(event) {
      let thiz = this;
      if (!this.mouseDown) {
        return;
      }
      var canvas = thiz.$refs["poster-cut-desktop"];
      var mouseX = event.clientX - canvas.getBoundingClientRect().left;
      var mouseY = event.clientY - canvas.getBoundingClientRect().top;
      var xOffSet = (mouseX - this.mouseDownX) / this.drawWidth;
      var yOffSet = (mouseY - this.mouseDownY) / this.drawHeight;
      switch (this.mouseAnchorPosition) {
        case 1:
          var posterCutX1Temp = this.posterCutDataWhenDown[0] + xOffSet;
          var posterCutY1Temp = this.posterCutDataWhenDown[1] + yOffSet;
          this.posterCutX2 = this.posterCutDataWhenDown[2];
          this.posterCutY2 = this.posterCutDataWhenDown[3];
          var distance = Math.pow(
            Math.pow(
              this.posterCutX2 * this.drawWidth -
                posterCutX1Temp * this.drawWidth,
              2
            ) +
              Math.pow(
                this.posterCutY2 * this.drawHeight -
                  posterCutY1Temp * this.drawHeight,
                2
              ),
            0.5
          );
          this.posterCutX1 =
            (this.posterCutX2 * this.drawWidth - (distance / 18.35) * 16) /
            this.drawWidth;
          this.posterCutY1 =
            (this.posterCutY2 * this.drawHeight - (distance / 18.35) * 9) /
            this.drawHeight;
          if (this.posterCutX1 < 0) {
            var gap = 0 - this.posterCutX1;
            this.posterCutY1 =
              this.posterCutY1 +
              (((gap * this.drawWidth) / 16) * 9) / this.drawHeight;
            this.posterCutX1 = 0;
          }
          if (this.posterCutY1 < 0) {
            var gap = 0 - this.posterCutY1;
            this.posterCutX1 =
              this.posterCutX1 +
              (((gap * this.drawHeight) / 9) * 16) / this.drawWidth;
            this.posterCutY1 = 0;
          }
          this.posterCutCanvasDraw();
          break;
        case 2:
          var posterCutX2Temp = this.posterCutDataWhenDown[2] + xOffSet;
          var posterCutY1Temp = this.posterCutDataWhenDown[1] + yOffSet;
          this.posterCutX1 = this.posterCutDataWhenDown[0];
          this.posterCutY2 = this.posterCutDataWhenDown[3];
          var distance = Math.pow(
            Math.pow(
              posterCutX2Temp * this.drawWidth -
                this.posterCutX1 * this.drawWidth,
              2
            ) +
              Math.pow(
                this.posterCutY2 * this.drawHeight -
                  posterCutY1Temp * this.drawHeight,
                2
              ),
            0.5
          );
          this.posterCutX2 =
            (this.posterCutX1 * this.drawWidth + (distance / 18.35) * 16) /
            this.drawWidth;
          this.posterCutY1 =
            (this.posterCutY2 * this.drawHeight - (distance / 18.35) * 9) /
            this.drawHeight;
          if (this.posterCutX2 > 1) {
            var gap = this.posterCutX2 - 1;
            this.posterCutY1 =
              this.posterCutY1 +
              (((gap * this.drawWidth) / 16) * 9) / this.drawHeight;
            this.posterCutX2 = 1;
          }
          if (this.posterCutY1 < 0) {
            var gap = 0 - this.posterCutY1;
            this.posterCutX2 =
              this.posterCutX2 -
              (((gap * this.drawHeight) / 9) * 16) / this.drawWidth;
            this.posterCutY1 = 0;
          }
          this.posterCutCanvasDraw();
          break;
        case 3:
          var posterCutX1Temp = this.posterCutDataWhenDown[0] + xOffSet;
          var posterCutY2Temp = this.posterCutDataWhenDown[3] + yOffSet;
          this.posterCutX2 = this.posterCutDataWhenDown[2];
          this.posterCutY1 = this.posterCutDataWhenDown[1];
          var distance = Math.pow(
            Math.pow(
              this.posterCutX2 * this.drawWidth -
                posterCutX1Temp * this.drawWidth,
              2
            ) +
              Math.pow(
                posterCutY2Temp * this.drawHeight -
                  this.posterCutY1 * this.drawHeight,
                2
              ),
            0.5
          );
          this.posterCutX1 =
            (this.posterCutX2 * this.drawWidth - (distance / 18.35) * 16) /
            this.drawWidth;
          this.posterCutY2 =
            (this.posterCutY1 * this.drawHeight + (distance / 18.35) * 9) /
            this.drawHeight;
          if (this.posterCutX1 < 0) {
            var gap = 0 - this.posterCutX1;
            this.posterCutY2 =
              this.posterCutY2 -
              (((gap * this.drawWidth) / 16) * 9) / this.drawHeight;
            this.posterCutX1 = 0;
          }
          if (this.posterCutY2 > 1) {
            var gap = this.posterCutY2 - 1;
            this.posterCutX1 =
              this.posterCutX1 +
              (((gap * this.drawHeight) / 9) * 16) / this.drawWidth;
            this.posterCutY2 = 1;
          }
          this.posterCutCanvasDraw();
          break;
        case 4:
          var posterCutX2Temp = this.posterCutDataWhenDown[2] + xOffSet;
          var posterCutY2Temp = this.posterCutDataWhenDown[3] + yOffSet;
          this.posterCutX1 = this.posterCutDataWhenDown[0];
          this.posterCutY1 = this.posterCutDataWhenDown[1];
          var distance = Math.pow(
            Math.pow(
              posterCutX2Temp * this.drawWidth -
                this.posterCutX1 * this.drawWidth,
              2
            ) +
              Math.pow(
                posterCutY2Temp * this.drawHeight -
                  this.posterCutY1 * this.drawHeight,
                2
              ),
            0.5
          );
          this.posterCutX2 =
            (this.posterCutX1 * this.drawWidth + (distance / 18.35) * 16) /
            this.drawWidth;
          this.posterCutY2 =
            (this.posterCutY1 * this.drawHeight + (distance / 18.35) * 9) /
            this.drawHeight;
          if (this.posterCutX2 > 1) {
            var gap = this.posterCutX2 - 1;
            this.posterCutY2 =
              this.posterCutY2 -
              (((gap * this.drawWidth) / 16) * 9) / this.drawHeight;
            this.posterCutX2 = 1;
          }
          if (this.posterCutY2 > 1) {
            var gap = this.posterCutY2 - 1;
            this.posterCutX2 =
              this.posterCutX2 -
              (((gap * this.drawHeight) / 9) * 16) / this.drawWidth;
            this.posterCutY2 = 1;
          }
          this.posterCutCanvasDraw();
          break;
        case 5:
          this.posterCutX1 = this.posterCutDataWhenDown[0] + xOffSet;
          this.posterCutY1 = this.posterCutDataWhenDown[1] + yOffSet;
          this.posterCutX2 = this.posterCutDataWhenDown[2] + xOffSet;
          this.posterCutY2 = this.posterCutDataWhenDown[3] + yOffSet;
          if (this.posterCutX1 < 0) {
            var gap = 0 - this.posterCutX1;
            var tempY1 =
              this.posterCutY1 +
              (((gap * this.drawWidth) / 16) * 9) / this.drawHeight / 2;
            var tempY2 =
              this.posterCutY2 -
              (((gap * this.drawWidth) / 16) * 9) / this.drawHeight / 2;
            this.posterCutY1 = tempY1;
            this.posterCutY2 = tempY2;
            this.posterCutX1 = 0;
          }
          if (this.posterCutY1 < 0) {
            var gap = 0 - this.posterCutY1;
            var tempX1 =
              this.posterCutX1 +
              (((gap * this.drawHeight) / 9) * 16) / this.drawWidth / 2;
            var tempX2 =
              this.posterCutX2 -
              (((gap * this.drawHeight) / 9) * 16) / this.drawWidth / 2;
            this.posterCutX1 = tempX1;
            this.posterCutX2 = tempX2;
            this.posterCutY1 = 0;
          }
          if (this.posterCutX2 > 1) {
            var gap = this.posterCutX2 - 1;
            var tempY1 =
              this.posterCutY1 +
              (((gap * this.drawWidth) / 16) * 9) / this.drawHeight;
            var tempY2 =
              this.posterCutY2 -
              (((gap * this.drawWidth) / 16) * 9) / this.drawHeight;
            this.posterCutY1 = tempY1;
            this.posterCutY2 = tempY2;
            this.posterCutX2 = 1;
          }
          if (this.posterCutY2 > 1) {
            var gap = this.posterCutY2 - 1;
            var tempX1 =
              this.posterCutX1 +
              (((gap * this.drawHeight) / 9) * 16) / this.drawWidth / 2;
            var tempX2 =
              this.posterCutX2 -
              (((gap * this.drawHeight) / 9) * 16) / this.drawWidth / 2;
            this.posterCutX1 = tempX1;
            this.posterCutX2 = tempX2;
            this.posterCutY2 = 1;
          }
          this.posterCutCanvasDraw();
          break;
        default:
          break;
      }
    },
    onPosterCutMouseEnd(event) {
      let thiz = this;
      thiz.mouseDown = false;
      thiz.mouseAnchorPosition = 0;
    },
    checkPosition(x, y) {
      if (this.checkDistance(x, y, this.drawX1, this.drawY1)) {
        return 1;
      }
      if (this.checkDistance(x, y, this.drawX2, this.drawY1)) {
        return 2;
      }
      if (this.checkDistance(x, y, this.drawX1, this.drawY2)) {
        return 3;
      }
      if (this.checkDistance(x, y, this.drawX2, this.drawY2)) {
        return 4;
      }
      if (
        x > this.drawX1 &&
        x < this.drawX2 &&
        y > this.drawY1 &&
        y < this.drawY2
      ) {
        return 5;
      }
      return 0;
    },
    checkDistance(x1, y1, x2, y2) {
      return Math.pow(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2), 0.5) < 20;
    },
    posterCutCanvasDraw(event) {
      let thiz = this;
      var canvas = thiz.$refs["poster-cut-desktop"];
      const ctx = canvas.getContext("2d");
      var cwidth = 544;
      var cheight = 306;
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, cwidth, cheight);
      ctx.drawImage(
        this.drawImage,
        0,
        0,
        this.posterOriWidth,
        this.posterOriHeight,
        this.drawX,
        this.drawY,
        this.drawWidth,
        this.drawHeight
      );
      // go draw select
      // draw bg
      var x1 = this.drawX + this.drawWidth * this.posterCutX1;
      var x2 = this.drawX + this.drawWidth * this.posterCutX2;
      var y1 = this.drawY + this.drawHeight * this.posterCutY1;
      var y2 = this.drawY + this.drawHeight * this.posterCutY2;
      thiz.drawX1 = x1;
      thiz.drawX2 = x2;
      thiz.drawY1 = y1;
      thiz.drawY2 = y2;
      ctx.fillStyle = "rgba(0,0,0,0.7)";
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(cwidth, 0);
      ctx.lineTo(cwidth, cheight);
      ctx.lineTo(0, cheight);
      ctx.lineTo(0, y2);
      ctx.lineTo(x2, y2);
      ctx.lineTo(x2, y1);
      ctx.lineTo(x1, y1);
      ctx.lineTo(x1, y2);
      ctx.lineTo(0, y2);
      ctx.closePath();
      ctx.fill();
      // draw leftop
      ctx.fillStyle = "rgba(0,0,255,0.9)";
      ctx.beginPath();
      ctx.moveTo(x1 - 10, y1 - 10);
      ctx.lineTo(x1 + 10, y1 - 10);
      ctx.lineTo(x1 + 10, y1 + 10);
      ctx.lineTo(x1 - 10, y1 + 10);
      ctx.closePath();
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(x1 - 10, y2 - 10);
      ctx.lineTo(x1 + 10, y2 - 10);
      ctx.lineTo(x1 + 10, y2 + 10);
      ctx.lineTo(x1 - 10, y2 + 10);
      ctx.closePath();
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(x2 - 10, y1 - 10);
      ctx.lineTo(x2 + 10, y1 - 10);
      ctx.lineTo(x2 + 10, y1 + 10);
      ctx.lineTo(x2 - 10, y1 + 10);
      ctx.closePath();
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(x2 - 10, y2 - 10);
      ctx.lineTo(x2 + 10, y2 - 10);
      ctx.lineTo(x2 + 10, y2 + 10);
      ctx.lineTo(x2 - 10, y2 + 10);
      ctx.closePath();
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(x1 - 2, y1 - 2);
      ctx.lineTo(x2 + 2, y1 - 2);
      ctx.lineTo(x2 + 2, y2 + 2);
      ctx.lineTo(x1 - 2, y2 + 2);
      ctx.lineTo(x1 - 2, y2 - 2);
      ctx.lineTo(x2 - 2, y2 - 2);
      ctx.lineTo(x2 - 2, y1 + 2);
      ctx.lineTo(x1 + 2, y1 + 2);
      ctx.lineTo(x1 + 2, y2 - 2);
      ctx.lineTo(x1 - 2, y2 - 2);
      ctx.closePath();
      ctx.fill();
    },
    onBeforePosterUpload(file) {
      let thiz = this;
      var fileName = file.name;
      var fileExtName = fileName
        .substring(fileName.lastIndexOf("."))
        .toLowerCase();
      console.log("fileExtName", fileExtName);
      if (!fileExtName.match(/.jpg|.jpeg/)) {
        this.$rtm.message.alert("文件格式必须为：jpg/jpeg", "格式错误");
        return false;
      }
      thiz.imgURL = URL.createObjectURL(file.raw);
      thiz.posterUUID = res.data;
    },
    onVideoSelected(file) {
      this.videoImg = URL.createObjectURL(file.raw);
    },
    onGetVideoURL(res, file) {
      this.uploading = false;
      console.log("onGetVideoURL file", file);
      console.log("res23", res);
      if (res.code != 0) {
        this.$msgbox({ message: res.msg, type: "warning" });
        this.videoUUID = "";
        this.videoProgress = 0;
        return;
      }
      var thiz = this;
      thiz.videoUUID = res.data;
      thiz.videoProgress = 100;
    },
    // 上传的进程
    onUploadProgress(event) {
      var thiz = this;
      this.uploading = true;
      thiz.videoProgress = (parseFloat(event.percent) - 1).toFixed(2);
    },
    // videoPostProgress
    onUploadPicProgress(event) {
      var thiz = this;
      thiz.videoPosterProgress = parseFloat(event.percent).toFixed(2);
    },
    onGoIndex() {
      this.$router.push("/index.html");
    },
  },
};
</script>
<style lang="less">
.el-radio__input .el-radio__inner {
  border: 1px solid #cf0000 !important;
}
</style>

<style lang="less" scoped>
.uppost {
  width: 100%;
}
@media all and (min-width: 801px) {
  .uppost-con {
    width: 120rem;
    margin: 0 auto;
    background-color: #ccc;
    .uppost-title {
      height: 6rem;
      width: 100%;
      text-align: left;
      line-height: 6rem;
      font-size: 1.6rem;
      margin-left: 5px;
      span {
        cursor: pointer;
        font-size: 1.6rem;
      }
      span:hover {
        color: red;
      }
    }
    .uppost-detail {
      width: 100%;
      .small-pic-preview {
        width: 100%;
        overflow: hidden;
        text-align: left;
        background-color: #ccc;
        img {
          height: 100px;
          display: inline-block;
          margin: 10px;
        }
      }
      .pic-list {
        width: 100%;
        overflow: hidden;
        background-color: #ccc;
        .pic-item {
          position: relative;
          height: 15rem;
          width: 96%;
          margin: 10px 2%;
          top: 0px;
          display: flex;
          .pic-image {
            width: 32rem;
            height: 15rem;
            background: no-repeat 50% / contain;
            position: relative;
            overflow: hidden;
            i {
              display: block;
              line-height: 40px;
              font-size: 25px;
              background-color: rgba(48, 38, 38, 0.4);
              color: rgb(226, 223, 223);
              border-radius: 20px;
              overflow: hidden;
              height: 40px;
              width: 40px;
              right: -50px;
            }
            .up {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 5px;
            }
            .close {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 55px;
            }
            .down {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 105px;
            }
          }
          .pic-image:hover {
            i {
              right: 50px;
            }
          }
          .pic-info {
            flex: 1;
            height: 150px;
            display: flex;
            flex-direction: column;
            .status-line {
              height: 30px;
            }
            .text {
              flex: 1;
            }
          }
        }
      }
      .up-con {
        border: 1px solid #f3e8c8;
        background-color: #fffeef;
        padding: 15px 30px;
        line-height: 50px;
        display: flex;
        font-size: 1.5rem;
        text-align: left;

        .up-left {
          width: 50%;
          .el-input__inner,
          .el-textarea__inner {
            border: 1px solid rgb(14, 3, 3) !important;
            font-family: "宋体" !important;
          }
          .title {
            display: inline-block;
            width: 100px;
          }
          .content {
            margin: 5px 0px;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 30px;
            }
          }
          div {
            .el-input {
              width: 70%;
            }
          }
        }
        .up-right {
          width: 50%;
          .save {
            margin-top: 30px;
            margin-left: 40%;
          }
        }
      }
      .add-uuid {
        height: auto;
        background: #ccc;
        margin: 10px;
        text-align: left;
        display: flex;
        justify-content: center;
        gap: 10px;
        .add-video {
          width: 64rem;
          height: auto;
          margin-bottom: 10px;
          .top {
            text-align: center;
            .video-progress {
              margin-top: 10px;
              height: 30px;
              position: relative;
              background-color: #ff7918;
              .progress-bar {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                background-color: red;
              }
              .progress-bar.finish {
                background-color: green;
              }
              .progress-title {
                height: 30px;
                position: relative;
                line-height: 30px;
                color: white;
                font-size: 1.4rem;
              }
            }
          }
          .show {
            height: 360px;
            margin-top: 10px;
            video {
              background-color: black;
              width: 100%;
              height: 100%;
            }
          }
        }
        .add-poster {
          width: 528px;
          height: auto;
          margin-bottom: 10px;
          .top {
            text-align: center;
            height: 7rem;
            .button-line {
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: center;
              gap: 10px;
              .el-button {
                height: 40px;
              }
              .tip {
                height: 40px;
                line-height: 40px;
                font-size: 22px;
              }
            }

            .pic-progress {
              margin-top: 10px;
              height: 30px;
              position: relative;
              background-color: #ff7918;
              .progress-bar {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                background-color: red;
              }
              .progress-bar.finish {
                background-color: green;
              }
              .progress-title {
                height: 30px;
                position: relative;
                line-height: 30px;
                color: white;
                font-size: 1.4rem;
              }
            }
          }
          .show {
            height: 29.7rem;
            margin-top: 18px;
            border: 1px solid #000;
            .poster-view {
              width: 100%;
              height: 100%;
              position: relative;
              overflow: hidden;
              .poster-view-inner {
                position: absolute;
                background: no-repeat 50% / contain black;
              }
            }
          }
        }
      }
    }
    .poster-cut-dialog {
      min-width: 1100px;
      .poster-cut-container {
        width: 100%;
        display: flex;
        gap: 10px;
        justify-content: center;
        gap: 20px;
        .poster-cut-view {
          height: 306px;
          width: 544px;
          position: relative;
          overflow: hidden;
          .title {
            position: absolute;
            color: white;
            text-shadow: 1px 5px 5px black;
            top: 5px;
            left: 5px;
            font-size: 22px;
          }
          canvas {
            height: 306px;
            width: 544px;
          }
          .poster-cut-view-inner {
            position: absolute;
            background: no-repeat 50% / contain black;
          }
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .uppost-con {
    width: 750px;
    margin: 0 auto;
    background-color: #ccc;
    .uppost-title {
      height: 60px;
      width: 100%;
      text-align: left;
      line-height: 60px;
      font-size: 14px;
      margin-left: 5px;
      span {
        cursor: pointer;
        font-size: 16px;
      }
      span:hover {
        color: red;
      }
    }
    .uppost-detail {
      width: 100%;
      .small-pic-preview {
        width: 100%;
        overflow: hidden;
        text-align: left;
        background-color: #ccc;
        img {
          height: 100px;
          display: inline-block;
          margin: 10px;
        }
      }
      .pic-list {
        width: 100%;
        overflow: hidden;
        background-color: #ccc;
        .pic-item {
          position: relative;
          height: 150px;
          width: 96%;
          margin: 10px 2%;
          top: 0px;
          display: flex;
          .pic-image {
            width: 320px;
            height: 150px;
            background: no-repeat 50% / contain;
            position: relative;
            overflow: hidden;
            i {
              display: block;
              line-height: 40px;
              font-size: 25px;
              background-color: rgba(48, 38, 38, 0.4);
              color: rgb(226, 223, 223);
              border-radius: 20px;
              overflow: hidden;
              height: 40px;
              width: 40px;
              right: -50px;
            }
            .up {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 5px;
            }
            .close {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 55px;
            }
            .down {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 105px;
            }
          }
          .pic-image:hover {
            i {
              right: 50px;
            }
          }
          .pic-info {
            flex: 1;
            height: 150px;
            display: flex;
            flex-direction: column;
            .status-line {
              height: 30px;
            }
            .text {
              flex: 1;
            }
          }
        }
      }
      .up-con {
        border: 1px solid #f3e8c8;
        background-color: #fffeef;
        padding: 15px 30px;
        line-height: 50px;
        display: flex;
        font-size: 1.3rem;
        text-align: left;

        .up-left {
          width: 50%;
          .el-input__inner,
          .el-textarea__inner {
            border: 1px solid rgb(14, 3, 3) !important;
            font-family: "宋体" !important;
          }
          .title {
            display: inline-block;
            width: 100px;
            font-size: 14px;
          }
          .content {
            margin: 5px 0px;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 30px;
            }
          }
          div {
            .el-input {
              width: 70%;
            }
          }
        }
        .up-right {
          width: 50%;
          .save {
            margin-top: 30px;
            margin-left: 40%;
          }
        }
      }
      .add-uuid {
        height: auto;
        background: #ccc;
        margin: 10px;
        text-align: left;
        display: flex;
        .add-video {
          width: 50%;
          height: auto;
          margin-bottom: 10px;
          .top {
            text-align: center;
            .video-progress {
              margin-top: 10px;
              height: 30px;
              position: relative;
              background-color: #ff7918;
              .progress-bar {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                background-color: red;
              }
              .progress-bar.finish {
                background-color: green;
              }
              .progress-title {
                height: 30px;
                position: relative;
                line-height: 30px;
                color: white;
              }
            }
          }
          .show {
            height: 400px;
            margin-top: 10px;
            video {
              background-color: black;
              width: 100%;
            }
          }
        }
        .add-poster {
          width: 48%;
          margin-left: 20px;
          height: auto;
          margin-bottom: 10px;
          .top {
            text-align: center;
            height: 70px;
            .el-button {
              height: 40px;
            }
            .button-line {
              width: 100%;
              display: flex;
            }
            .pic-progress {
              margin-top: 10px;
              height: 30px;
              position: relative;
              background-color: #ff7918;
              .progress-bar {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                background-color: red;
              }
              .progress-bar.finish {
                background-color: green;
              }
              .progress-title {
                height: 30px;
                position: relative;
                line-height: 30px;
                color: white;
              }
            }
          }
          .show {
            height: 297px;
            margin-top: 18px;
            border: 1px solid #000;
            img {
              width: 100%;
              height: 100%;
              background-size: cover;
            }
          }
        }
      }
    }
  }
}
</style>
